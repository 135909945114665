import type { RouteObject } from 'react-router-dom';
import type { RouteProps } from '../shared/RouteProps';
import { WriteAReviewLayout } from './layouts/WriteAReviewLayout';
import { CompanyProfilesPage } from '../paths';
import { writeAReviewLoader } from './layouts/writeAReviewLoader';

export const createCompanyReviewFormRoutes = (
  props: RouteProps,
): RouteObject[] => [
  {
    path: CompanyProfilesPage.WriteAReview,
    element: <WriteAReviewLayout />,
    id: CompanyProfilesPage.WriteAReview,
    loader: writeAReviewLoader(props),
  },
];
