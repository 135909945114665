import { CompanyProfilesPage } from '../../../paths';
import translations from './.vocab';
import { getTranslations } from './translations';

const t = getTranslations(translations);

export const metaBrand = {
  seek: 'SEEK',
  jobstreet: 'JobStreet',
  jobsdb: 'JobsDB',
} as const satisfies Record<string, string>;

type MetaBrand = (typeof metaBrand)[keyof typeof metaBrand];

export interface MetaDataInput {
  tab?: CompanyProfilesPage;
  companyTitle?: string;
  reviewCount?: number;
  companyProfileImage?: string;
  language: string;
  brand: MetaBrand;
  brandUrl?: string;
  absoluteUrl: string;
}

export interface MetaData {
  title: string;
  description: string;
  companyProfileImage?: string;
  absoluteUrl: string;
  shouldDisableIndexing?: boolean;
}

export const getMetaData = ({
  tab,
  companyTitle,
  reviewCount = 0,
  companyProfileImage,
  language,
  brand,
  brandUrl,
  absoluteUrl,
}: MetaDataInput): MetaData => {
  const shouldDisableIndexing = Boolean(brand === 'SEEK' && reviewCount < 3);
  const absoluteUrlWithoutQueryParams = absoluteUrl.split('?')[0];

  switch (tab) {
    case CompanyProfilesPage.About:
      return {
        title: t(
          language,
          'Working at {companyTitle} company profile and information | {brand}',
          { companyTitle, brand },
        ),
        description: t(
          language,
          'Considering a career at {companyTitle}? Learn what its like to work for {companyTitle} by reading employee ratings and reviews on {brandUrl}',
          { companyTitle, brandUrl },
        ),
        companyProfileImage,
        absoluteUrl: absoluteUrlWithoutQueryParams,
        shouldDisableIndexing,
      };
    case CompanyProfilesPage.Culture:
      return {
        title: t(
          language,
          '{companyTitle} work culture, perks & benefits | {brand}',
          { companyTitle, brand },
        ),
        description: t(
          language,
          "Learn what it's like to work at {companyTitle} by reading up on {companyTitle}'s culture, benefits & approach to diversity - all on {brand} Company Reviews.",
          { companyTitle, brand },
        ),
        companyProfileImage,
        absoluteUrl: absoluteUrlWithoutQueryParams,
        shouldDisableIndexing,
      };
    case CompanyProfilesPage.Jobs:
      return {
        title: t(
          language,
          '{companyTitle} job openings and vacancies | {brand}',
          { companyTitle, brand },
        ),
        description: t(
          language,
          'View all current job openings at {companyTitle} here on {brandUrl}',
          { companyTitle, brandUrl },
        ),
        companyProfileImage,
        absoluteUrl: absoluteUrlWithoutQueryParams,
        shouldDisableIndexing,
      };
    case CompanyProfilesPage.Reviews:
      return {
        title: t(
          language,
          'Reviews {companyTitle} employee ratings and reviews | {brand}',
          { companyTitle, brand },
        ),
        description: `${t(language, 'Read {reviewCount}', {
          reviewCount: reviewCount.toLocaleString(),
        })} ${
          reviewCount > 1 ? t(language, 'reviews') : t(language, 'review')
        } ${t(
          language,
          'for {companyTitle}. Real reviews by real company employee past and present here on {brandUrl}',
          { companyTitle, brandUrl },
        )}`,
        companyProfileImage,
        absoluteUrl: absoluteUrlWithoutQueryParams,
        shouldDisableIndexing,
      };
    default:
      return {
        title: t(language, 'Company Profiles and Reviews | {brand}', { brand }),
        description: t(
          language,
          'Search ratings, reviews and jobs for almost every company here on {brandUrl}',
          { brandUrl },
        ),
        companyProfileImage,
        absoluteUrl: absoluteUrlWithoutQueryParams,
      };
  }
};
