/* istanbul ignore file */
// example component, will be updated
import { Column, Columns, Dropdown } from 'braid-design-system';
import type { Month, WorkHistory } from '../type';

export const WorkHistoryQuestion = ({
  value = {},
  message = '',
  tone,
  onChange,
}: {
  value?: WorkHistory;
  tone?: 'critical' | 'neutral';
  message?: string;
  onChange: (value: WorkHistory) => void;
}) => {
  const handleFromMonthOnChange = (
    event: React.FormEvent<HTMLSelectElement>,
  ) => {
    onChange({
      ...value,
      from: {
        ...value.from,
        month: event.currentTarget.value as Month,
      },
    });
  };

  const handleFromYearOnChange = (
    event: React.FormEvent<HTMLSelectElement>,
  ) => {
    onChange({
      ...value,
      from: {
        ...value.from,
        year: event.currentTarget.value,
      },
    });
  };

  const handleToMonthOnChange = (event: React.FormEvent<HTMLSelectElement>) => {
    onChange({
      ...value,
      to: {
        ...value.to,
        month: event.currentTarget.value as Month,
      },
    });
  };

  const handleToYearOnChange = (event: React.FormEvent<HTMLSelectElement>) => {
    onChange({
      ...value,
      to: {
        ...value.to,
        year: event.currentTarget.value,
      },
    });
  };

  return (
    <>
      <Columns space="small" alignY="bottom">
        <Column>
          <Dropdown
            id="fromMonth"
            label="When did you start?"
            onChange={handleFromMonthOnChange}
            value={value.from?.month ?? ''}
            placeholder="Month"
            tone={tone}
          >
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
            <option>6</option>
            <option>7</option>
            <option>8</option>
            <option>9</option>
            <option>10</option>
            <option>11</option>
            <option>12</option>
          </Dropdown>
        </Column>
        <Column>
          <Dropdown
            id="fromYear"
            label=""
            onChange={handleFromYearOnChange}
            value={value.from?.year ?? ''}
            placeholder="Year"
            tone={tone}
            aria-label="When did you start?"
          >
            <option>2024</option>
            <option>2023</option>
            <option>2022</option>
            <option>2021</option>
          </Dropdown>
        </Column>
        <Column>
          <p></p>
        </Column>
      </Columns>
      <Columns space="small" alignY="bottom">
        <Column>
          <Dropdown
            id="toMonth"
            label="When did you finish?"
            onChange={handleToMonthOnChange}
            value={value.to?.month ?? ''}
            placeholder="Month"
            tone={tone}
            message={message}
          >
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
            <option>6</option>
            <option>7</option>
            <option>8</option>
            <option>9</option>
            <option>10</option>
            <option>11</option>
            <option>12</option>
          </Dropdown>
        </Column>
        <Column>
          <Dropdown
            id="toYear"
            label=""
            onChange={handleToYearOnChange}
            value={value.to?.year ?? ''}
            placeholder="Year"
            tone={tone}
            aria-label="When did you finish?"
          >
            <option>2024</option>
            <option>2023</option>
            <option>2022</option>
            <option>2021</option>
          </Dropdown>
        </Column>
        <Column>
          <p></p>
        </Column>
      </Columns>
    </>
  );
};
