export const ROOT_PATH = 'companies';

export enum CompanyProfilesPage {
  Home = ROOT_PATH,
  About = ':companySlug',
  Culture = 'culture',
  Jobs = 'jobs',
  Reviews = 'reviews',
  CompanySearch = 'search',
  WriteAReview = 'write-a-review-v2',
}
