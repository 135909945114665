/* istanbul ignore file */
// example component, will be updated
import { useState } from 'react';
import { useValidation } from '@seek/libs-shared';

import type { ReviewPayload } from '../type';
import { Page1 } from './Page1';
import { Page2 } from './Page2';
import { validationRules } from '../validation/validationRules';
import type { RoleTitle } from '../../shared/services/roleService/roleService';

export type Props = {
  getRoleTitles?: (query: string) => Promise<RoleTitle[]>;
};

export const WriteAReviewPage = (props: Props) => {
  const [review, setReview] = useState<ReviewPayload>({});
  const [page, setPage] = useState(1);

  const { validate } = useValidation<ReviewPayload>(review, validationRules);

  const handleOnSubmit = async () => {
    const isValid = validate(review);
    if (!isValid) {
      return;
    }
    alert('success');
  };

  const handleOnSetPage = (page: number) => {
    setPage(page);
  };

  return (
    <>
      <p>Write a review</p>
      {page === 1 && (
        <Page1 setPage={handleOnSetPage} review={review} setter={setReview} />
      )}
      {page === 2 && (
        <Page2
          experientNumber={1}
          setPage={handleOnSetPage}
          setter={setReview}
          onSubmit={handleOnSubmit}
          review={review}
        />
      )}
    </>
  );
};
