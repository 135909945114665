/* istanbul ignore file */
// example component, will be updated
import type { PageProps, ReviewPayload } from '../type';
import { validationRules } from '../validation/validationRules';
import { useValidation, type Rules } from '@seek/libs-shared';
import { TheGoodThingsQuestion } from '../questions/TheGoodThingsQuestion';
import { Button, Stack } from 'braid-design-system';

export const Page1 = ({ review, setPage, setter }: PageProps) => {
  const fields = {
    theGoodThings: review.theGoodThings,
  };

  const rules = {
    theGoodThings: validationRules.theGoodThings,
  } as unknown as Rules<typeof fields>;

  const { validate, getTone, getMessage, handleValueChangeWithValidation } =
    useValidation<ReviewPayload>(fields, rules);

  const onNext = () => {
    const isValid = validate();
    if (!isValid) {
      return;
    }
    setPage(2);
  };

  return (
    <Stack space="xlarge">
      <TheGoodThingsQuestion
        onChange={handleValueChangeWithValidation({
          setter,
          validatorProperty: 'theGoodThings',
          previousValue: review,
        })}
        value={review.theGoodThings}
        tone={getTone('theGoodThings')}
        message={getMessage('theGoodThings')}
      />

      <Button variant="solid" tone="formAccent" onClick={onNext}>
        {'Next'}
      </Button>
    </Stack>
  );
};
