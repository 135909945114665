import { useState } from 'react';
import { WriteAReviewPage } from './WriteAReviewPage';
import { v4 as uuid } from 'uuid';
import { useService } from '../../shared/providers/serviceProvider';
import type { RoleTitle } from '../../shared/services/roleService/roleService';

export const WriteAReviewContainer = () => {
  const [sessionId, setSessionId] = useState<string>(uuid());

  const { roleService } = useService();

  const getRoleTitles = async (query: string): Promise<RoleTitle[]> => {
    // If text is cleared, generate a new session id
    let currentSessionId = sessionId;
    if (query === '') {
      currentSessionId = uuid();
      setSessionId(currentSessionId);
    }

    return await roleService.getRoleTitles(query, currentSessionId);
  };

  return <WriteAReviewPage getRoleTitles={getRoleTitles} />;
};
