/* istanbul ignore file */
// example component, will be updated
import { TextField } from 'braid-design-system';
import type { QuestionProps } from '../type';

export const CompanyNameQuestion = ({
  value = '',
  message = '',
  tone,
  onChange,
}: QuestionProps) => (
  <TextField
    id={'companyName'}
    label={'Company Name'}
    onChange={(event) => onChange(event.currentTarget.value)}
    onClear={() => onChange('')}
    value={value}
    tone={tone}
    message={message}
  />
);
