/* istanbul ignore file */
// example component, will be updated
import { useValidation, type Rules } from '@seek/libs-shared';
import type { PageProps, ReviewPayload } from '../type';
import { validationRules } from '../validation/validationRules';
import { CompanyNameQuestion } from '../questions/CompanyNameQuestion';
import { TheChallengesQuestion } from '../questions/TheChallengesQuestion';
import { WorkHistoryQuestion } from '../questions/WorkHistoryQuestion';
import { Button, Stack } from 'braid-design-system';

export const Page2 = ({
  review,
  experientNumber,
  setPage,
  setter,
  onSubmit,
}: PageProps) => {
  const fields = {
    ...(experientNumber === 1 ? { companyName: review.companyName } : {}),
    theChallenges: review.theChallenges,
    workHistory: review.workHistory,
  };

  const rules = {
    ...(experientNumber === 1
      ? { companyName: validationRules.companyName }
      : {}),
    theChallenges: validationRules.theChallenges,
    workHistory: validationRules.workHistory,
  } as unknown as Rules<typeof fields>;

  const { validate, getTone, getMessage, handleValueChangeWithValidation } =
    useValidation<ReviewPayload>(fields, rules);

  const handleOnSubmit = async () => {
    const isValid = validate();
    if (!isValid) {
      return;
    }
    if (onSubmit) onSubmit();
  };

  const handleOnPrevious = () => {
    setPage(1);
  };

  return (
    <Stack space="xlarge">
      {experientNumber === 1 && (
        <CompanyNameQuestion
          onChange={handleValueChangeWithValidation({
            setter,
            validatorProperty: 'companyName',
            previousValue: review,
          })}
          value={review.companyName}
          tone={getTone('companyName')}
          message={getMessage('companyName')}
        />
      )}
      <TheChallengesQuestion
        onChange={handleValueChangeWithValidation({
          setter,
          validatorProperty: 'theChallenges',
          previousValue: review,
        })}
        value={review.theChallenges}
        tone={getTone('theChallenges')}
        message={getMessage('theChallenges')}
      />
      <WorkHistoryQuestion
        onChange={handleValueChangeWithValidation({
          setter,
          validatorProperty: 'workHistory',
          previousValue: review,
        })}
        value={review.workHistory}
        tone={getTone('workHistory')}
        message={getMessage('workHistory')}
      />

      <Button variant="solid" tone="formAccent" onClick={handleOnSubmit}>
        {'Submit'}
      </Button>

      <Button variant="solid" tone="formAccent" onClick={handleOnPrevious}>
        {'Preivous'}
      </Button>
    </Stack>
  );
};
