/* istanbul ignore file */
// example component, will be updated
import { Textarea } from 'braid-design-system';
import type { QuestionProps } from '../type';

export const TheChallengesQuestion = ({
  value = '',
  message = '',
  tone,
  onChange,
}: QuestionProps) => (
  <Textarea
    id={'theChallenges'}
    label={'The Challenges'}
    onChange={(event) => onChange(event.currentTarget.value)}
    value={value}
    tone={tone}
    message={message}
  />
);
