//* istanbul ignore file */
// example component, will be updated
import { createValidator } from '@seek/validators-js';
import translations from './.vocab';
import type { WorkHistory } from '../../type';

export const { isWorkHistoryValid } = createValidator({
  test: ({ value }: { value: WorkHistory }) => Boolean(value?.from),
  formatErrorMessages: (messages) => () =>
    messages['Invalid work history'].format(),
  translations,
  validatorName: 'isWorkHistoryValid',
});
