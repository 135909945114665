import type { LoaderFunction } from 'react-router';

import type { RouteProps } from '../../../shared/RouteProps';
import type {
  CompanyBrandingSectionModel,
  CompanyProfile,
} from '@seek/libs-shared';

const extractCompanyIdFromSlug = (input?: string): string | undefined => {
  if (!input) {
    return undefined;
  }
  const parts = input.split('-');
  return parts[parts.length - 1];
};

const mapCompanyProfileHeroModel = (
  companyProfile: CompanyProfile,
): CompanyBrandingSectionModel => ({
  name: companyProfile.name,
  branding: companyProfile.branding,
  rating: companyProfile.rating,
  id: companyProfile.id,
});

export const companyProfileLoader =
  ({ services }: RouteProps): LoaderFunction =>
  async ({ params, request }): Promise<CompanyBrandingSectionModel> => {
    const companyId = extractCompanyIdFromSlug(params.companySlug);

    if (companyId === undefined) {
      throw new Error('Could not find companyId from params', params);
    }

    const url = new URL(request.url);
    const isDraft = url.searchParams.get('draft');

    const companyProfile =
      await services.companyProfileService.getCompanyProfile(
        companyId,
        Boolean(isDraft),
      );

    return mapCompanyProfileHeroModel(companyProfile);
  };
