/* istanbul ignore file */
// example component, will be updated
import { maxLength, required } from '@seek/validators-js';
import { isWorkHistoryValid } from './validator/isWorkHistoryValid';

export const validationRules = {
  companyName: {
    validators: [maxLength, required],
    validatorProps: {
      max: 100,
      requiredMessage: 'companyName is required',
    },
  },
  theGoodThings: {
    validators: [maxLength, required],
    validatorProps: {
      max: 300,
      requiredMessage: 'The Good Things is required',
    },
  },
  theChallenges: {
    validators: [maxLength, required],
    validatorProps: {
      max: 300,
      requiredMessage: 'The Challenges is required',
    },
  },
  workHistory: {
    validators: [isWorkHistoryValid],
  },
};
